export const normalizeProposal = (proposal) => {
  return {
    id: proposal.id,
    name: proposal.name,
    documentNumber: proposal.documentNumber,
    phoneNumber: proposal.phoneNumber,
    cnpjConsigneeId: proposal.cnpjConsigneeId,
    cnpjConsigneeName: proposal.cnpjConsigneeName,
    created: proposal.created,
    type: proposal.type,
    status: proposal.status,
    url: proposal.url,
    proposalNumber: proposal?.proposalNumber,
    loanDetails: proposal.loanDetails.map((loan) => ({
      loanType: loan.loanType,
      proposalNumber: loan.proposalNumber,
      purchaseMargin: Number((loan?.purchaseMargin * 100).toFixed()),
    })),
    documents: proposal.documents,
    loanType: proposal.loanType,
  };
};
